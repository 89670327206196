<template>
  <div class="meta-list-pagination">
    <span class="pagination-item">{{(currentIndex-1)*size+1}}-{{(currentIndex-1)*size+currentCount}} on&nbsp;</span><strong class="pagination-item">{{ totalCount }}</strong>
    <el-pagination
        :current-page="currentIndex"
        :page-size="size"
        layout=" prev,next "
        :total="totalCount"
        @size-change="$emit('size-change')"
        @current-change="$emit('current-change',$event)"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "MetaListPagination",
  props:{
    size:{
      type:Number,
      default:20
    },
    currentIndex:{
      type:Number,
      default:0
    },
    currentCount:{
      type:Number,
      default:0
    },
    totalCount:{
      type:Number,
      default:0
    }
  }
}
</script>

<style scoped>
.meta-list-pagination{
  display: flex;
  align-items: center;
}
.pagination-item{
  color:#777;
}
</style>
