<template>
  <div class="h-100 material-icons-loaded bg-concord-near-white concord-dark-gray fw4 h-100 mh0 pa0 roboto  ember-application application   pace-done" style="height: 100vh; overflow-y: hidden">
    <div class="h-100 h-100 flex flex-column">
      <div class="flex h-100">
        <contract-side-menu :active-index="5"></contract-side-menu>
        <div class="right-panel">
          <MetaRightSideHeader>
            <template #title>
              审批流程
            </template>
            <template #middle-right>
              <el-button class="border-rect-btn" @click="gotoEdit(null)" data-tag-id="add-meta-approval-process" :disabled="!checkUserBehaviorPermission(userInfo.mainSiteTeamId,BehaviorPermission.MANAGE_TEAM_APPROVAL_FLOW)">添加审批流程</el-button>
            </template>
            <template #bottom-tip>
                    此处可设置团队的审批流程并分配审批人。对团队审批流程的修改将会对新起草的合同生效。
            </template>
          </MetaRightSideHeader>

<!--          <div class="header-container">-->
<!--            <div class="flex items-center" style="justify-content: space-between">-->
<!--              <h2 class="fw4 f4 gray mv0 pr4 flex items-center size-medium">审批流</h2>-->
<!--              <div><el-button class="border-rect-btn" @click="gotoEdit(null)">添加审批流</el-button></div>-->

<!--            </div>-->
<!--            <div class="flex items-center pv2">-->
<!--              <div class="gray">-->
<!--                <p>此处可设置团队的审批流并分配审批人。对团队审批流的修改将会对新起草的合同生效。-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="content-container shadow bg-white">
            <MetaListTable
                :table-data="processList"
                :table-height="'100%'"
                @cell-click="gotoEdit"
                :show-checkbox="true"

            >
              <template v-slot:header>
                <div class="action">
                  <div class="selector-wrap">
                    <MetaListSelector
                      :list="processList"
                    >
                      <template #operation>
                        <div class="menu-item" @click="deleteApprovals"><i class="el-icon-delete"></i><span class="ml2">删除</span></div>

                      </template>
                    </MetaListSelector>
                  </div>
                  <div class="pagination-area">
                    <MetaListPagination
                        :current-count="currentCount"
                        :size="size"
                        :current-index="currentIndex"
                        :total-count="totalCount"
                        @size-change="sizeChange"
                        @current-change="currentChange"
                    ></MetaListPagination>
                  </div>
                </div>
              </template>
              <template v-slot:default>
                <el-table-column
                    align="left"
                    prop="approvalProcess.name"
                    label="流程名称"
                    min-width="200"
                >
                  <template #default="scope">
                    <span >{{scope.row.approvalProcess.name}}</span>
                  </template>
                </el-table-column>


                <el-table-column
                    align="left"
                    prop="approvalProcess.description"
                    label="适用范围"
                    min-width="200"
                >
                  <template #default="scope">
                    <span >{{scope.row.approvalProcess.description}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="date"
                    label="审批步骤&审批人"
                    min-width="200"
                >
                  <template #default="scope">

                    <div>
                      <div class="stage" v-for="(item,index) in scope.row.approvalStages" :key="index">
                        <div class="stage-index">{{index+1}}</div>
                        <approval-member :orders="item.orders" :idx="item.id" main_placement="bottom" sub_placement="right-start">
                          <template #reference>

                            <user-icon  v-for="(order,index2) in item.orders.slice(0,5)" style="margin-left: -8px"  :key="index2" :url="order.approverHeadImgUrl" :name="order.approverName" :uid="order.approverName"></user-icon>
                            <user-icon  style="margin-left: -8px" v-if="item.orders.length>5"  :name="'+'+(item.orders.length-5)" :uid="'count'" :count="2" :size="'small'"></user-icon>
                          </template>
                        </approval-member>
                      </div>
                    </div>

                  </template>
                </el-table-column>
<!--                <el-table-column-->
<!--                    label="日历管理人员"-->
<!--                >-->
<!--                  <template #default="scope">-->
<!--                    <div class="flex">-->
<!--                      <members :members="scope.row.calendarManagers" main_placement="bottom" sub_placement="left-start">-->
<!--                        <template #reference>-->
<!--                          <div style="display:flex;flex-wrap: wrap">-->
<!--                            <div v-for="(item,index) in scope.row.calendarManagers" :key="index" style="line-height: 40px;margin-left: -8px">-->
<!--                              <user-icon  :url="item.headImgUrl" :name="item.userName" :uid="item.userName"></user-icon>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </template>-->
<!--                      </members>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </el-table-column>-->
              </template>


            </MetaListTable>
         </div>
<!--            <div class="content-header"></div>-->
<!--            <div class="content-body">-->
<!--              <el-table :data="processList"  height="100%" style="width: 100%"-->
<!--                row-style="cursor:pointer"-->
<!--                @cell-click="gotoEdit"-->
<!--                        v-if="initFinished"-->
<!--              ></el-table>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>

</template>

<script>
import ContractSideMenu from "../../components/ContractSideMenu";
import {
  deleteMetaApprovalProcessById,
  getTeamUser,
  getTeamMetaApprovalProcessDataList,
  getTeamOrganizations,
  deleteMetaApprovalProcessByIds
} from "../../api/api";
import {colorLog, getHashColor, getUserName} from "../../util";

import ApprovalMember from "../../components/ApprovalMember";
import Members from "../../components/Members";
import UserIcon from "../../components/UserIcon";
import {ElNotification} from "element-plus";
import MetaListTable from "../../components/list/MetaListTable";
import MetaListSelector from "../../components/list/MetaListSelector";
import MetaRightSideHeader from "../../components/universalUI/MetaRightSideHeader";
import {checkUserBehaviorPermission,BehaviorPermission} from "../../components/common/permissionUtil";
import MetaListPagination from "../../components/list/MetaListPagination";

export default {
  name: "ContractApprovals",
  components: {
    MetaListPagination,
    MetaRightSideHeader, MetaListSelector, MetaListTable, UserIcon, Members, ApprovalMember, ContractSideMenu},
  data(){
    return{
      currentIndex: 1,
      size: 10,
      totalCount: 0,
      totalPage: 0,
      currentCount:0,
      processList:[],
      userInfo:JSON.parse(sessionStorage.getItem("userInfo")),
      addMetaApprovalDialogVisible:false,
      getHashColor,
      getUserName,
      initFinished:false,
      checkFilterMenuVisible:false,
      checkedList:[],
      deleteMenuVisible:false,
      checkUserBehaviorPermission,
      BehaviorPermission,

    }
  },
  mounted() {
    if(this.$route.query.pageIndex){
      this.currentIndex = this.$route.query.pageIndex;
    }
    this.loadData()
  },
  watch:{
    'processList':{
      handler:function (){
        this.checkedList=this.processList.filter((item)=>item.checked);
      },
      deep:true
    },
    '$route.query.pageIndex': function(){

      this.loadData();
    },
  },
  methods:{
    loadData:function (){
      this.initFinished=false
      getTeamMetaApprovalProcessDataList(this.currentIndex-1,this.size).then(res=>{
        if(res.status==200){
          if(res.data.code==0){
            this.processList=res.data.data.content;
            this.totalCount = res.data.data.totalElements;
            this.totalPage = res.data.data.totalPages;
            this.currentCount = res.data.data.numberOfElements;
          }
        }

      });

    },
    gotoEdit(row,column,cell,event){
      if(row){
        this.$router.push({name:'approvalEdit',params:{id:row.approvalProcess.id}})
      }else{
        this.$router.push({name:'approvalEdit'})
      }
    },
    deleteApprovals:function(){
      let successDelete = [];
      let failDelete = [];
      for (const i in this.checkedList) {
        if(this.checkedList[i].canDelete){
          successDelete.push(this.checkedList[i].approvalProcess.id);
        }
        else{
          failDelete.push(this.checkedList[i].approvalProcess.id);
        }
      }
      deleteMetaApprovalProcessByIds({ids:successDelete}).then(res => {
        if (res.status == 200 && res.data.code==0){
          ElNotification({
            title: '执行结果',
            message: `成功:${successDelete.length}个,`+
                    `失败:${failDelete.length}`
          })
          let tempProcessList =[];
          if (successDelete.length != 0){
            let i = 0,j = 0;
            while(j<this.processList.length || i<successDelete.length){
              if (successDelete[i] != this.processList[j].approvalProcess.id){
                tempProcessList.push(this.processList[j++]);
              }else{
                j++;
                i++;
              }
            }
            if (j < this.processList.length){
              while(j<this.processList.length){
                tempProcessList.push(this.processList[j++]);
              }
            }
            this.processList = tempProcessList;
          }
          this.processList.forEach((item)=>{
            item.checked=false;
          })
        }else{
          ElNotification({
            title: '执行结果',
            message: `成功:0个,失败:${failDelete.length+successDelete.length}`
          })
        }
      }).catch(e => {
        console.log("delete approvals fail =>" ,e)
        this.metaMessage['error']('网络错误,请稍后再试');
      });
    },
    currentChange: function (newValue) {
      this.currentIndex=newValue;

      this.$router.push({
        name:'approvals',
        query:{
          pageIndex:this.currentIndex
        }
      })

    },
  }
}
</script>

<style scoped>

.content-container  .meta-list-table /deep/ .el-table .cell{
  height: unset !important;
}

.right-panel{
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
  height: 100%;
  width: calc(100% - 283px);
}

.content-container{
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100% - 154px);

}

.header-right{
  display: flex;
  align-items: center;
}

.content-header{
  display: flex;
  border-bottom: solid 1px #dddddd;
  padding: 7px 0;
}

.content-body{
  flex: 1;
  height: calc(100% - 46px);


}

.checkbox{
  margin-right: 10px;
  vertical-align: middle;
  display: inline-block;
  height: 12px;
  width: 12px;
  background: white;
  border: solid 1px #dcdfe6;
  line-height: 8px;
  text-align: center;
}


.checkbox span{
  width: 10px;
  height: 2px;
  background: white;
  display: inline-block;
  vertical-align: middle;

}
.all-checked span,.any-checked span{

  background: #1E9FFF;
}
.all-checked{
  background: #1E9FFF;
  border-color:#1E9FFF;;
}
.any-checked{
  background:white;
  border-color:#1E9FFF;;
}

.stage{
  display: flex;
  margin-right: 10px;
  align-items: center;

  line-height: 40px;

}

.stage-index{
  padding-right: 5px;
  border-right: solid 1px #ddd;
  margin-right: 10px;
  height: 20px;
  line-height: 20px;

}

.stage-order{
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius: 20px;
  color: white;

  margin-right: 5px;
  overflow: hidden;
  flex-shrink: 0;
}
.content-body >>> .el-table__body-wrapper {

}


.content-body >>> .el-table{
  overflow: unset;
}

.content-body >>> .el-table .cell {
  overflow: unset;
}

.flex{
  /*overflow-x: hidden;*/
  /*overflow-y: visible;*/
}

.action-btn-menu {
  width: 70px;
  z-index: 9999;
  position: absolute;
  top: 30px;
  background: white;
  left: -12px;
  box-shadow: rgb(60 64 67 / 15%) 0 1px 3px 1px;
}

.menu-item {

  padding: 10px;
  text-align: left;
  cursor: pointer;
}

.ml2 {
  margin-left: 1rem;
}

.selector-wrap{
  flex:1
}
</style>
